import React from "react"

export const AudioPlayer = ({ isRootPath }) => {
  return (
    <div
      style={isRootPath ? { background: "#1F1F1F" } : { background: "white" }}
    >
      <audio
        controls
        autoPlay
        loop
        style={{ height: "30px", width: "200px", padding: "0 50px" }}
      >
        <source src="../sounds/Drone-type-C-low-G.ogg" type="audio/ogg" />
      </audio>
    </div>
  )
}
