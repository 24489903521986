import * as React from "react"
import { Link } from "gatsby"
import { AudioPlayer } from "./AudioPlayer"
import { Layout as antdLayout } from "antd"

const { Header } = antdLayout

const Layout = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const title = "pratiprasava"
  let header

  if (isRootPath) {
    header = (
      <Header className="header-link-home" style={{ color: "white" }}>
        <Link to="/pratiprasava/">{title}</Link>
      </Header>
    )
  } else {
    header = (
      <Header
        className="blog-header"
        // Color is set to white to hide the text in the blog post
        style={{ background: "white", color: "white" }}
      >
        <Link></Link>
      </Header>
    )
  }

  return (
    <div>
      <div className={isRootPath ? "audio-header-index" : "audio-header-blog"}>
        {header}
        <AudioPlayer isRootPath={isRootPath} />
      </div>
      <div className={isRootPath ? "" : "global-wrapper"}>
        <main>{children}</main>
        <footer>© {new Date().getFullYear()}</footer>
      </div>
    </div>
  )
}

export default Layout
